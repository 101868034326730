import React, { useMemo } from 'react';
import { snakeCase } from 'lodash';
import { StringService, useTranslation } from '@apollo/core';
import { LocalConfig } from '../../../../core/utils';

const MarketTypeDescription = ({ marketTypeId }) => {
  const translation = useTranslation();

  const description = useMemo(() => {
    const themeName = `${LocalConfig.get('THEME_NAME')}`.split('.')[0]?.toLowerCase();
    let messageKey = snakeCase(`Market Type Desc ${marketTypeId} ${themeName}`);

    let message = translation[messageKey];

    if (!message) {
      // translation for all sites
      messageKey = snakeCase(`Market Type Desc ${marketTypeId}`);
      message = translation[messageKey];
    }

    if (!message) {
      return null;
    }

    return StringService.format(message);
  }, [translation, marketTypeId]);

  if (!description?.trim()) {
    return null;
  }

  return (
    <div className='MarketTypeDescription'>
      <p>{description}</p>
    </div>
  );
};

export default MarketTypeDescription;
