import { useMemo, useRef, useState } from 'react';
import { chain, concat, isEqual, isNumber, isString, some, toNumber } from 'lodash';
import { isVisible as checkIsVisible } from '@apollo/core';
import { marketGroupTypes, sportBetTypes } from '../../../core/constants';
import useTranslate from '../../../shared/components/I18n/Interpreter';

const filterGroups = (group) => isString(group) || isNumber(group);

const useEventDetails = (event) => {
  const translate = useTranslate();
  const { activeMarketGroups = [], marketTypes } = event;
  const prevMarketGroupsRef = useRef(null);
  const [activeBetType, setActiveBetType] = useState(sportBetTypes.Single);

  const { marketGroups, betGroups } = useMemo(() => {
    let marketGroups = activeMarketGroups.map(({ groupId }) => groupId);
    let betGroups = [];

    chain(marketTypes)
      .sortBy('orderPosition')
      .forEach((marketType) => {
        const { groups, betGroup, markets } = marketType;
        const hasVisibleMarkets = some(markets, checkIsVisible);
        if (hasVisibleMarkets) {
          marketGroups = concat(marketGroups, groups);
          betGroups.push(toNumber(betGroup));
        }
      })
      .value();

    marketGroups = chain(marketGroups)
      .filter(filterGroups)
      .uniq()
      .map((groupId) => {
        const groupMatch = activeMarketGroups.find((g) => g.groupId === groupId);

        // Legacy test: Remove in future version
        const singleEnabledMarketsCount = groupMatch && groupMatch.singleEnabledMarketsCount === undefined
          ? undefined
          : groupMatch?.singleEnabledMarketsCount || 0;

        const sgmEnabledMarketsCount = groupMatch?.sgmEnabledMarketsCount || 0;
        return {
          text: translate(groupId),
          value: { group: groupId, type: marketGroupTypes.marketGroup },
          singleEnabledMarketsCount,
          sgmEnabledMarketsCount,
          singleEnabledMarketTypesCount: groupMatch?.singleEnabledMarketTypesCount || 0,
          sgmEnabledMarketTypesCount: groupMatch?.sgmEnabledMarketTypesCount || 0,
        };
      })
      // the Bet Group 'ALL' should not be available in the backoffice
      .filter(({ value: { group } }) => group.toUpperCase() !== 'ALL')
      .value();

    betGroups = chain(betGroups)
      .filter(filterGroups)
      .uniq()
      .sort()
      .map((betGroup) => ({
        text: `${betGroup} ${translate('bet_group')}`,
        value: { group: betGroup, type: marketGroupTypes.betGroup },
      }))
      .value();

    if (betGroups.length === 1) {
      betGroups = [];
    }

    const groups = marketGroups;

    if (!isEqual(groups, prevMarketGroupsRef.current)) {
      prevMarketGroupsRef.current = groups;
    }

    return { marketGroups: prevMarketGroupsRef.current, betGroups };
  }, [activeMarketGroups, marketTypes, translate]);

  return {
    activeBetType,
    setActiveBetType,
    marketGroups,
    betGroups,
  };
};

export default useEventDetails;
