import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { prepareRequest, SettingsManager, useApplicationState } from '@apollo/core';
import { transactionTypes } from '../../../core/constants';
import Label from '../../../shared/components/I18n/Label';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import ToastManager from '../../../core/ToastManager';

const scheduleCallAfter = (timeMillis) => (func) => new Promise((resolve, reject) => {
  setTimeout(() => {
    Promise.resolve(func()).then(resolve).catch(reject);
  }, timeMillis);
});

const noop = () => {};

const DepositPayIdInfo = ({
  savedCredentials,
  savedCredentialsLoading,
  paymentMethodId,
  onSuccess,
}) => {
  const [payId, setPayId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const latestRequest = useRef();
  const isMounted = useRef();
  const { account } = useApplicationState();
  const t = useTranslate();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (savedCredentials) {
      const savedCredentialsForDeposit = (savedCredentials || []).find(
        (c) => c.operationType === transactionTypes.DEPOSIT,
      );
      if (savedCredentialsForDeposit?.data?.payId) {
        setPayId(savedCredentialsForDeposit?.data?.payId);
      }
    } else {
      setPayId(null);
    }
  }, [savedCredentials]);

  const handleRequestCredentials = () => {
    setIsLoading(true);
    // eslint-disable-next-line no-multi-assign
    const request = (latestRequest.current = prepareRequest({
      url: 'payment/client-meta/deposit-credentials:request',
      method: 'POST',
      headers: {
        lang: SettingsManager.getSettings().lang,
      },
    })({ pmId: paymentMethodId })
      .then((response) => {
        if (isMounted.current) {
          if (response.status === 'COMPLETE') {
            return onSuccess();
          }
          if (response.status === 'FAILED') {
            return setIsError(true);
          }
          // if not complete then schedule a new call in 5 seconds to check status
          return scheduleCallAfter(5000)(handleRequestCredentials);
        }
      })
      .finally(() => {
        if (isMounted.current && request === latestRequest.current) {
          setIsLoading(false);
        }
      }));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(payId);
    ToastManager.success(t('Copied'));
  };

  if (payId) {
    return (
      <div className='pay-id__wrapper'>
        <div className='pay-id--box'>{payId}</div>
        <button type='button' className='bBasePrimary pay-id--button' onClick={handleCopy}>
          <Label message='copy PayId' />
        </button>
      </div>
    );
  }

  if (savedCredentialsLoading) {
    return null;
  }

  const clientAccountNotVerified = account?.clientVerificationState?.toLowerCase() !== 'verified';

  return isError ? (
    <Label message='payid_request_error' className='text-danger' />
  ) : (
    <div className='pay-id__wrapper'>
      <button
        type='button'
        className='bBasePrimary pay-id--button'
        onClick={clientAccountNotVerified ? noop : handleRequestCredentials}
        disabled={clientAccountNotVerified}
      >
        {isLoading ? <Label message='loading' /> : <Label message='Request PayID' />}
      </button>
      {clientAccountNotVerified ? <p>{t('payid_not_verified_account_note')}</p> : null}
    </div>
  );
};

DepositPayIdInfo.propTypes = {
  // eslint-disable-next-line react/require-default-props
  savedCredentials: PropTypes.arrayOf(
    PropTypes.shape({
      operationType: PropTypes.string,
      data: PropTypes.shape({
        payId: PropTypes.string,
      }),
    }),
  ),
  // eslint-disable-next-line react/require-default-props
  savedCredentialsLoading: PropTypes.bool,
  paymentMethodId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default DepositPayIdInfo;
