import React, { useCallback, useEffect, useMemo } from 'react';
import { useSettings } from '@apollo/core';
import Label from '../../components/I18n/Label';
import AuthBlock from '../AuthBlock/AuthBlock';
import StaticBlock from '../../components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';
import useTranslate from '../../components/I18n/Interpreter';
import { LocalConfig } from '../../../core/utils';

const Version = () => {
  const { isCordova, platform } = useSettings();
  const iosHref = LocalConfig.get('apps.ios', null);
  const androidHref = LocalConfig.get('apps.android', null);

  const isIOS = useMemo(
    () => isCordova && platform?.toUpperCase() === 'IOS',
    [isCordova, platform],
  );

  const isAndroid = useMemo(
    () => isCordova && platform?.toUpperCase() === 'ANDROID',
    [isCordova, platform],
  );

  const handleAnchorClick = useCallback(
    (event) => {
      if (!isCordova) {
        return;
      }
      event.preventDefault();
      const anchor = event.currentTarget;
      const href = anchor.getAttribute('href');
      window.open(href, '_system');
      return false;
    },
    [isCordova],
  );

  const appStoreUrl = useMemo(() => {
    if (isIOS) {
      return iosHref;
    }
    if (isAndroid) {
      return androidHref;
    }
    return null;
  }, [isIOS, isAndroid, iosHref, androidHref]);

  return (
    <AuthBlock allowClose={false}>
      <div className='b-row'>
        <div className='auth-block-modal__form b-col'>
          <header className='auth-block-modal__container__header'>
            <h1 className='auth-block-modal__container__header__title'>
              <Label message='version_block' />
            </h1>
          </header>
          <div className='auth-block-modal__form__top'>
            <div className='auth-block-modal__form__top__inputs stretch'>
              <div className='block'>
                <StaticBlock options={{ source: cmsStaticBlock.VersionBlock }} />
              </div>
            </div>
          </div>
          {appStoreUrl && (
            <footer className='auth-block-modal__container__footer'>
              <div>
                <a
                  href={appStoreUrl}
                  target='_blank'
                  onClick={handleAnchorClick}
                  className='bBasePrimary'
                  rel='noreferrer'
                >
                  Update now
                </a>
              </div>
            </footer>
          )}
        </div>
      </div>
    </AuthBlock>
  );
};

export default Version;
