import React, { memo, useMemo } from 'react';
import { DEPOSIT_PROCESSING_TYPE } from '@apollo/react-forms';
import { useApplicationState } from '@apollo/core';
import usePaymentLimitsTranslate from './usePaymentLimitsTranslate';
import StaticBlock from '../../../shared/components/StaticBlock/StaticBlock';

const PaymentFormFooter = ({ payment, staticBlockSource, type }) => {
  const { account } = useApplicationState();
  const paymentLimits = usePaymentLimitsTranslate(payment, type);

  const renderStaticBlock = useMemo(
    () => (payment.depositType === DEPOSIT_PROCESSING_TYPE.PAYID ? null : (
      <StaticBlock options={{ source: staticBlockSource }} params={account} />
    )),
    [staticBlockSource, payment.depositType, account],
  );

  return (
    <>
      <div className='subText'>
        <span>{paymentLimits}</span>
      </div>
      {renderStaticBlock}
    </>
  );
};

export default memo(PaymentFormFooter);
