import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DateUtils, SiteConfigManager } from '@apollo/core';
import { RaceTypeAll } from '@apollo/core/src/constants';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { COMPONENT_TYPES } from '@apollo/routing';
import { Helmet } from 'react-helmet-async';
import { specialDates } from '../../core/constants';
import { LocalConfig } from '../../core/utils';
import useTranslate from '../../shared/components/I18n/Interpreter';

export const raceDateParser = (raceDate) => {
  if (!raceDate) {
    return DateUtils.todayDateKey();
  }
  switch (raceDate.toUpperCase()) {
    case specialDates.Today:
      return DateUtils.todayDateKey();

    case specialDates.Tomorrow:
      return DateUtils.futureDateKey(1);

    default:
      return raceDate;
  }
};

export const nextToJumpPath = '/next-to-jump';

const RacingPathHandler = (props) => {
  const { children } = props;

  const t = useTranslate();
  const routeParams = useParams();
  const { pathname } = useLocation();

  const { raceDate, raceType, raceCountry, raceVenue, raceNumber, marketTab, marketType } = routeParams;

  const [racingState, racingDispatcher] = useRacingState();
  const {
    meetings,
    races,
    raceDate: stateRaceDate,
    activeRaceId,
    loading,
    activeLoaders,
    initialized,
  } = racingState;

  const _raceDate = useMemo(() => raceDateParser(raceDate), [raceDate]);

  useEffect(() => {
    const compareRaceNumber = (n1, n2) => String(n1) === String(n2) || !n2;
    const isMeetingLoaded = meetings.length > 0;

    const meetingRaces = isMeetingLoaded
      && meetings
        .find(
          (meeting) => meeting?.raceType?.typeName?.toUpperCase() === raceType?.toUpperCase()
            && meeting?.date === _raceDate
            && meeting?.country?.toUpperCase() === raceCountry?.toUpperCase()
            && meeting?.venueName?.toUpperCase() === raceVenue?.toUpperCase(),
        )
        ?.races?.sort((a, b) => a.number - b.number);

    const race = meetingRaces?.length
      ? meetingRaces?.find((r) => compareRaceNumber(r.number, raceNumber)) || meetingRaces[0]
      : false;

    /* const activeMeet = (meetings || []).find((m) => {

    raceType: {typeName:nr.type},
            meeting: {meetingId: nr.meetingId, date:nr.date},
            venue: {venueId: nr.venueId, country: nr.country, venueName: nr.venueName}

      if (m.date !== _raceDate) {
        return false;
      }
      if (m.raceType.typeName !== raceType) {
        return false;
      }
      if (m.venue.country !== raceCountry) {
        return false;
      }
      if (m.venue.venueName !== raceVenue) {
        return false;
      }
      return true;
    });

    if (activeMeet == null) {
      return;
    }

    const rn = raceNumber || '1';
    let race = activeMeet.races.find((r) => r.number.toString() === rn);
    if (!race) {
      race = activeMeet.races?.[0];
    } */

    if (race && race.raceId !== activeRaceId) {
      racingDispatcher({
        type: RacingActions.RACING_SET_ACTIVE_RACE_ID,
        payload: race.raceId,
      });
    }
  }, [meetings, _raceDate, raceType, raceCountry, raceVenue, raceNumber]);

  useEffect(() => {
    racingDispatcher({
      type: RacingActions.RACING_PATH_UPDATE,
      payload: {
        raceDate: _raceDate,
        raceType: raceType ?? RaceTypeAll.typeName,
        raceCountry,
        raceVenue,
        raceNumber,
        marketTab,
        activeMarketTypeId: marketType,
      },
    });
  }, [
    stateRaceDate,
    raceDate,
    raceType,
    raceCountry,
    raceVenue,
    raceNumber,
    marketTab,
    marketType,
  ]);

  // if (!initialized || loading) {
  //  return <RaceLoader />;
  // }

  const racingPath = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RACING}`),
    [],
  );

  const isNextToJump = useMemo(
    () => pathname === `${racingPath}${nextToJumpPath}`,
    [pathname, racingPath],
  );

  const metaTitle = useMemo(() => {
    if (isNextToJump) {
      return t('next_to_jump');
    }
    const nameParts = [];
    if (raceNumber) {
      nameParts.push(`R${raceNumber}`);
    }
    if (raceVenue) {
      nameParts.push(raceVenue);
    }
    if (raceCountry) {
      nameParts.push(raceCountry);
    }
    if (raceType) {
      nameParts.push(raceType);
    }
    return `${nameParts.join(', ')}`;
  }, [isNextToJump, raceNumber, raceVenue, raceCountry, raceType]);

  const headMeta = useMemo(
    () => ({
      title: `${LocalConfig.get('public.title')} ${t('page_title')}`,
      description: `${metaTitle}. ${LocalConfig.get('public.description')}`,
      keywords: `${metaTitle}, ${LocalConfig.get('public.keywords')}`,
    }),
    [metaTitle],
  );

  return (
    <>
      <Helmet>
        <title>{`${metaTitle} Racing - ${headMeta.title}`}</title>
        <meta name='description' content={headMeta.description} />
        <meta name='keywords' content={headMeta.keywords} />
      </Helmet>
      {children}
    </>
  );
};
export default RacingPathHandler;
