import React from 'react';
import { Link } from 'react-router-dom';
import { staticRoutes } from '@apollo/routing';
import Theme from 'themeSource/custom';
import CmdLink from '../CmdLink';
import Label from '../I18n/Label';

const { signup } = staticRoutes;

const SignUpLink = ({ onClickHandler, className = 'btn btn-registration' }) => (Theme.USE_SIGN_UP_MODAL ? (
  <CmdLink className={className} cmd='signup' onClick={onClickHandler}>
    <Label message='registration' />
  </CmdLink>
) : (
  <Link className={className} to={signup} onClick={onClickHandler}>
    <Label message='registration' />
  </Link>
));

export default SignUpLink;
