import React, { useMemo } from 'react';
import { useSportState } from '@apollo/core/src/state/sport/sport';
import { useHistory } from 'react-router-dom';
import { at, isEmpty } from 'lodash';
import Label from '../../../shared/components/I18n/Label';
import Logo from '../../../shared/components/Logo/Logo';
import { getEventDate, groupEventsByDate } from '../../../core/utils';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { getEventUrl } from '../../../shared/components/Sport/SportLinks';

const EventListSelect = ({ selectedEvent, events, onEventChange }) => {
  const t = useTranslate();

  const [sportsState] = useSportState();
  const history = useHistory();

  const { sportsById, tournamentsById, categoriesById } = sportsState;
  const tournament = useMemo(
    () => selectedEvent && tournamentsById && tournamentsById[selectedEvent.tournamentId],
    [selectedEvent, tournamentsById],
  );

  const [homeName, homeId, homeLogoUri, awayName, awayId, awayLogoUri] = at(selectedEvent, [
    'participants.home.fullName',
    'participants.home.id',
    'participants.home.logoUri',
    'participants.away.fullName',
    'participants.away.id',
    'participants.away.logoUri',
  ]);

  const onChange = (eventId) => {
    const event = events.find((e) => `${e.id}` === eventId);
    if (!event) {
      return null;
    }
    const sport = sportsById && sportsById[event.sportId];
    const category = categoriesById && categoriesById[event.categoryId];

    const url = getEventUrl({ sport, category, tournament, event });

    onEventChange(event); // show selected event immediately
    history.push(url);
  };

  const eventsByDate = useMemo(() => groupEventsByDate(events), [events]);

  return (
    <div className='event-list-select'>
      {/* tournament ? <div className='event-list-select-tournament'>{tournament.name}</div> : null */}

      <div className='event-list-select-wrapper'>
        <h1 className='event-name' title={`${homeName} - ${awayName}`}>
          <div className='event-participants__participant'>
            <Logo
              participantId={homeId}
              logoUri={homeLogoUri}
              size='lg'
              alt={homeName}
              isHideEmpty
            />
            <div className='event-participants__participant-name'>{homeName}</div>
          </div>
          <span>
            <Label message='versus' />
          </span>
          <div className='event-participants__participant'>
            <Logo
              participantId={awayId}
              logoUri={awayLogoUri}
              size='lg'
              alt={awayName}
              isHideEmpty
            />
            <div className='event-participants__participant-name'>{awayName}</div>
          </div>
        </h1>
        {!isEmpty(eventsByDate) ? (
          <select
            className='event-select'
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            {eventsByDate.map((eventsGroup) => (
              <optgroup key={eventsGroup[0].id} label={getEventDate(eventsGroup[0].startTime, t)}>
                {eventsGroup.map((event) => (
                  <option key={event.id} value={event.id} selected={event.id === selectedEvent?.id}>
                    {event.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        ) : null}
      </div>
    </div>
  );
};

export default EventListSelect;
