import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { SettingsManager, SiteConfigManager, useApplicationState } from '@apollo/core';

import { clientProfileRoutes, COMPONENT_TYPES, responsibleGamingRoutes } from '@apollo/routing';

import { onSignUpSubmit, useActiveForm } from '@apollo/react-forms';
import Navigation from '../../../features/SignUp/Navigation';
import ToastManager from '../../../core/ToastManager';
import FormCom from '../Form/FormCom';
import Label from '../I18n/Label';
import StaticBlock from '../StaticBlock/StaticBlock';
import Loader from '../Loader';
import { cmsStaticBlock } from '../../../core/constants';
import FormStepsIndicator from '../Form/FormStepsIndicator/FormStepsIndicator';
import CmdLink from '../CmdLink';

const { responsibleGaming } = clientProfileRoutes;
const { selfRegulation } = responsibleGamingRoutes;
const { CLIENT_PROFILE } = COMPONENT_TYPES;

const SignUpForm = ({ affiliateId = null }) => {
  const { types, activeType, setActiveType, submit, stepsAmount, setActiveStep, activeStep } = useActiveForm('CLIENT_REGISTRATION', onSignUpSubmit);
  const [formData, setFormData] = useState([]);

  const { push: historyPush } = useHistory();
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const applicationState = useApplicationState();
  const authenticated = get(applicationState, 'authenticated');

  const initialCaptchaKey = get(activeType, 'optionalParams.captchaKey');
  const initialCaptchaRequired = get(activeType, 'optionalParams.captchaRequired');

  const isLastStep = stepsAmount && activeStep === activeType.steps[stepsAmount - 1];

  useEffect(() => {
    if (authenticated) {
      if (isLastStep) {
        historyPush(`${clientProfilePath}/${responsibleGaming}/${selfRegulation}`);
      }
      historyPush('/');
    }
  }, [historyPush, clientProfilePath, authenticated, isLastStep]);

  useEffect(() => {
    if (!affiliateId) {
      return;
    }
    SettingsManager.updateSettings({
      affiliateId: String(affiliateId).toUpperCase(),
    });
  }, [affiliateId]);

  const changeStep = (data) => {
    const newFormData = [...formData];
    newFormData[activeStep.number] = data;

    setFormData(newFormData);
    setActiveStep(activeStep.number + 1);
  };

  const submitFormData = (data, ...props) => {
    const newFormData = [...formData];
    newFormData[activeStep.number] = data;

    setFormData(newFormData);
    // console.log(445, Object.assign({}, ...newFormData));
    submit(Object.assign({}, ...newFormData), ...props);
  };

  const onSubmit = useCallback(() => {
    if (!isLastStep) {
      return;
    }

    ToastManager.success('welcome');
  }, [isLastStep]);

  const renderForm = activeStep ? (
    <FormCom
      id={activeType.id}
      className='form--signup'
      action={isLastStep ? submitFormData : changeStep}
      onSubmit={onSubmit}
      form={activeStep}
      buttonText={isLastStep ? 'registration' : 'next'}
      initialCaptchaKey={initialCaptchaKey}
      initialCaptchaRequired={initialCaptchaRequired}
      initialValues={formData[activeStep.number] || {}}
    />
  ) : (
    <Loader />
  );

  const handleStepBack = () => {
    if (activeStep.number > 0) {
      setActiveStep(activeStep.number - 1);
    }
  };

  return (
    <div className='signup__form'>
      <header className='signup__container__header'>
        <StaticBlock options={{ source: cmsStaticBlock.SignUpHeader }}>
          <div className='signup__container__header__logo'>
            <div className='logo-img' />
          </div>
        </StaticBlock>

        <h1 className='signup__container__header__title'>
          <Label message='registration' />
        </h1>

        {activeType?.steps && activeStep?.number && activeType.steps.length > 1 ? (
          <button type='button' onClick={handleStepBack} className='steps-form-back-btn'>
            <span className='icon AIcon-angle-left' />
          </button>
        ) : null}

        <FormStepsIndicator
          steps={activeType?.steps}
          activeStep={activeStep?.number}
          setActiveStep={setActiveStep}
        />
      </header>
      <div className='signup__form__top'>
        <div className='signup__form__top__inputs stretch'>
          <div className='block'>
            <Navigation types={types} onChange={setActiveType} activeType={activeType} />
            {renderForm}
          </div>
        </div>
      </div>
      <div className='signup__form__bottom'>
        <Label message='have account already' />
        <CmdLink className='login-link' cmd='signin'>
          <Label message='login' />
        </CmdLink>
      </div>
    </div>
  );
};

export default SignUpForm;
