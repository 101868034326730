import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { onPasswordResetSubmit, useActiveForm } from '@apollo/react-forms';
import ToastManager from '../../../core/ToastManager';
import Label from '../../components/I18n/Label';
import FormCom from '../../components/Form/FormCom';
import Loader from '../../components/Loader';
import AuthBlock from '../AuthBlock/AuthBlock';

const ResetPassword = ({ formType = 'PASSWORD_RESET' }) => {
  const { activeStep, submit } = useActiveForm(formType, onPasswordResetSubmit);
  const { pathname, search } = useLocation();
  const { replace: historyReplace } = useHistory();

  const token = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return searchParams.get('token');
  }, [search]);

  const submitHandler = useCallback(() => {
    ToastManager.success(() => <Label message='New password was changed successfully' />);
    historyReplace(pathname, { search: '' });
  }, [pathname, historyReplace]);

  return (
    <AuthBlock>
      <div className='b-row'>
        <div className='auth-block-modal__form b-col reset-password__form'>
          <header className='auth-block-modal__container__header'>
            <h1 className='auth-block-modal__container__header__title'>
              <Label message='new_password' />
            </h1>
          </header>

          <div className='auth-block-modal__form__top'>
            <div className='auth-block-modal__form__top__inputs stretch'>
              <div className='block'>
                <div className='block__content'>
                  {activeStep ? (
                    <FormCom
                      additionalValues={{ resetToken: token }}
                      form={activeStep}
                      action={submit}
                      buttonText='accept'
                      onSubmit={submitHandler}
                      className='reset-password-form'
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthBlock>
  );
};

export default ResetPassword;
