import React, { useCallback, useMemo, useState } from 'react';
import Theme from 'themeSource/custom';
import { map } from 'lodash';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import SportListItem from './SportListItem';

const noCategoryToggle = Theme.DISABLE_SPORTS_DROPDOWN_MOBILE || false;

const SportList = ({ isOrderByName = false, sports = SportSelector.selectSports() }) => {
  const [activeSport, setActiveSport] = useState();

  const [sportsState] = useSportState();
  const { tournamentsBySportId } = sportsState;

  const sortedSports = useMemo(
    () => (isOrderByName
      ? sports.filter((s) => s.totalCount > 0).sort((a, b) => a.name.localeCompare(b.name))
      : sports),
    [isOrderByName, sports],
  );

  const toggleActiveSport = useCallback(
    (id) => {
      if (id === activeSport) {
        setActiveSport(null);
      } else {
        setActiveSport(id);
      }
    },
    [activeSport],
  );

  return map(sortedSports, (sport) => {
    const { id } = sport;
    return (
      <SportListItem
        key={id}
        active={activeSport === id}
        sport={sport}
        tournaments={tournamentsBySportId[id] || []}
        onToggleActiveSport={noCategoryToggle ? null : () => toggleActiveSport(id)}
      />
    );
  });
};

export default SportList;
