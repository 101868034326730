import React, { useEffect, useMemo, useState } from 'react';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { isHidden } from '@apollo/core';
import { chain } from 'lodash';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import EventListSelect from '../../../src/app/features/Sports/DetailsPage/EventListSelect';
import CountdownTimer from '../../../src/app/shared/components/CountdownTimer/CountdownTimer';
import TopEventTime from '../../../src/app/features/Sports/TopEvents/TopEvent/TopEventTime';
import { getEventMainMarket } from '../../../src/app/core/utils';

const DetailPageHeader = ({ event }) => {
  DetailPageHeader.displayName = 'BetMax.DetailPageHeader';
  const [sportsState] = useSportState();
  const { selectedTournamentId } = sportsState;

  const selectedTournament = SportSelector.selectTournamentById(selectedTournamentId);
  const events = SportSelector.selectEventsByTournamentId(selectedTournamentId);

  const [selectedEvent, setSelectedEvent] = useState(event);

  const filteredEvents = useMemo(
    () => chain(events)
      .filter((e) => {
        if (isHidden(e) || !hasMarketTypes(e)) {
          return false;
        }
        if (e.outright) {
          return false;
        }
        return getEventMainMarket(e);
      })
      .value(),
    [event, events],
  );

  useEffect(() => {
    if (!event.loading) {
      setSelectedEvent(event);
    }
  }, [event]);

  const onEventChange = (event) => setSelectedEvent(event);

  if (!selectedTournament) {
    return null;
  }

  return (
    <div className='event-details eventView'>
      <div className='event-details__top'>
        <span className='event-details__top__cover' />
        <div className='event-details__inner'>
          {/* <EventListDropdown events={filteredEvents} activeEvent={event} /> */}
          <EventListSelect
            events={filteredEvents}
            selectedEvent={selectedEvent}
            onEventChange={onEventChange}
          />
          <div className='event-details__timer'>
            <CountdownTimer time={selectedEvent?.startTime} key={selectedEvent?.startTime} />
          </div>
          <span className='top-event-status__time'>
            <TopEventTime event={event} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DetailPageHeader;
