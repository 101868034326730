import React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';
import { CategoryLink } from '../../../shared/components/Sport/SportLinks';

const SportListItemHeader = ({
  className,
  sportName,
  toggleExpand = null,
  sportUrlName = null,
  categoryUrlName = null,
  active = null,
  id = null,
}) => {
  const classes = cx('sport-list__item-header', `sport-${id}`, className);

  // if (sportsLink && sportUrlName) {
  if (!isFunction(toggleExpand) && sportUrlName) {
    return (
      <CategoryLink
        sportUrlName={sportUrlName}
        categoryUrlName={categoryUrlName}
        className={classes}
      >
        <div>
          <span className={`iconResult sportIcon-${id}`} />
          <span className='name'>{sportName}</span>
          <span className={`arrow AIcon-angle-right ${active ? 'active' : ''}`} />
        </div>
      </CategoryLink>
    );
  }

  return (
    <div className={classes} onClick={toggleExpand}>
      <div>
        <span className={`iconResult sportIcon-${id}`} />
        <span className='name'>{sportName}</span>
        <span className={`arrow AIcon-angle-right ${active ? 'active' : ''}`} />
      </div>
    </div>
  );
};

export default SportListItemHeader;
