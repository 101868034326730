import React from 'react';
import _, { isString } from 'lodash';
import Logo from '../../../shared/components/Logo/Logo';

const SportOutcomeLogo = ({ event, name, className, children = null }) => {
  const [homeName, homeId, homeLogoUri, awayName, awayId, awayLogoUri] = _.at(event, [
    'participants.home.fullName',
    'participants.home.id',
    'participants.home.logoUri',
    'participants.away.fullName',
    'participants.away.id',
    'participants.away.logoUri',
  ]);

  if (!name || !isString(name)) {
    return null;
  }
  if (name.startsWith(homeName) && !name.includes('/')) {
    // it contains both participants with '/' for some outcomes AP-2146
    return (
      <Logo
        participantId={homeId}
        logoUri={homeLogoUri}
        size='lg'
        alt={name}
        isHideEmpty
        className={className}
      />
    );
  }

  if (name.startsWith(awayName) && !name.includes('/')) {
    return (
      <Logo
        participantId={awayId}
        logoUri={awayLogoUri}
        size='lg'
        alt={name}
        isHideEmpty
        className={className}
      />
    );
  }

  return children;
};

export default SportOutcomeLogo;
