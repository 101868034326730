import React, { useCallback, useState } from 'react';
import { get, isNull } from 'lodash';
import Input from '../../Input/Input';
import { getIsFieldRequired } from '../../../../../core/utils';
import Label from '../../../I18n/Label';

const DEFAULT_MIN = 8;
const DEFAULT_MAX = 32;

const PasswordValidation = ({ inputValue, rules }) => {
  const minLengthRule = rules.find((rule) => rule.type === 'MIN_LENGTH');
  const maxLengthRule = rules.find((rule) => rule.type === 'MAX_LENGTH');
  const includesUpperCaseRule = rules.find((rule) => rule.type === 'INCLUDES_UPPER_CASE_LETTER');
  const includesLowerCaseRule = rules.find((rule) => rule.type === 'INCLUDES_LOWER_CASE_LETTER');
  const includesSymbolRule = rules.find((rule) => rule.type === 'INCLUDES_SYMBOL');
  const includesNumberRule = rules.find((rule) => rule.type === 'INCLUDES_NUMBER');

  return (
    <div className='password-input-validation'>
      <ul>
        {minLengthRule && (
          <li
            className={
              inputValue.length >= parseInt(minLengthRule.ruleOption || DEFAULT_MIN)
              && (!maxLengthRule
                || inputValue.length <= parseInt(maxLengthRule.ruleOption || DEFAULT_MAX))
                ? 'validated'
                : ''
            }
          >
            <Label
              message={maxLengthRule ? 'min_max_characters' : 'min_characters'}
              params={{
                min: minLengthRule.ruleOption || DEFAULT_MIN,
                max: maxLengthRule.ruleOption || DEFAULT_MAX,
              }}
            />
          </li>
        )}
        {includesUpperCaseRule && (
          <li className={/[A-Z]/.test(inputValue) ? 'validated' : ''}>
            <Label message='has_an_upper_case' />
          </li>
        )}
        {includesLowerCaseRule && (
          <li className={/[a-z]/.test(inputValue) ? 'validated' : ''}>
            <Label message='has_an_lower_case' />
          </li>
        )}
        {includesSymbolRule && (
          <li className={/[!@#$%^&*(),.?":{}|<>]/.test(inputValue) ? 'validated' : ''}>
            <Label message='has_a_symbol' />
          </li>
        )}
        {includesNumberRule && (
          <li className={/\d/.test(inputValue) ? 'validated' : ''}>
            <Label message='has_a_number' />
          </li>
        )}
      </ul>
    </div>
  );
};

const TextField = ({ field, onChange, onFocus, onBlur }) => {
  const {
    touched,
    value,
    autofocus,
    errors,
    warnings,
    active,
    label,
    placeholder,
    disabled,
    name,
    rules,
  } = field;

  const inputValue = isNull(value) ? '' : value;
  const [isValidationVisible, setIsValidationVisible] = useState(false);
  const changeHandler = useCallback((event) => onChange(event.target.value), [onChange]);
  const error = touched && errors[0];
  const warning = touched ? get(warnings, '[0].message') : null;
  const success = touched && !error;

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const cmd = params.get('cmd');

  const handleFocus = (e) => {
    setIsValidationVisible(true);
    onFocus(e);
  };

  const handleBlur = (e) => {
    setIsValidationVisible(false);
    onBlur(e);
  };

  return (
    <>
      <Input
        type='password'
        name={name}
        error={error}
        warning={warning}
        value={inputValue}
        placeholder={placeholder}
        success={success}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={changeHandler}
        autoFocus={autofocus}
        touched={touched}
        active={active}
        disabled={disabled}
        label={label}
        isRequiredSymbol={getIsFieldRequired(rules)}
        withTranslate
      />

      {((cmd === 'signup' && name.toLowerCase() === 'password')
        || name.toLowerCase() === 'new_password')
        && isValidationVisible && <PasswordValidation inputValue={inputValue} rules={rules} />}
    </>
  );
};

export default TextField;
