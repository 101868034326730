import { SportSelector } from '@apollo/core/src/state/sport/sport';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import SportEvents from '../../../../features/Home/Tabs/Sports/SportEvents';
import useTranslate from '../../I18n/Interpreter';

const UpcomingSports = ({ title = null, isSwipeableCarousel }) => {
  const t = useTranslate();
  const finalTitle = useMemo(() => (!isEmpty(title) ? title : t('upcoming_sports')), [title]);

  const upcomingSports = SportSelector.selectUpcomingEvents({
    limit: 50,
    excludeOutright: true,
  });

  if (isEmpty(upcomingSports) === true) {
    return null;
  }

  return (
    <div className='home-sports-list home-sports-list--upcoming-sports'>
      <SportEvents
        title={finalTitle}
        events={upcomingSports}
        isSwipeableCarousel={isSwipeableCarousel}
      />
    </div>
  );
};
export default UpcomingSports;
