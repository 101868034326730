import React from 'react';
import cx from 'classnames';
import Label from '../../../../../shared/components/I18n/Label';

const ProgressBar = ({
  current = 0,
  required = 100,
  valueLabel = '',
  title = '',
  tooltip = '',
}) => {
  const progress = Math.min(100, Math.round((current / required) * 100));

  const classes = cx(
    'progress-bar',
    `progress-bar--${progress}`,
    progress === 0 ? 'progress-bar--none' : null,
    progress < 10 ? 'progress-bar--started' : null,
    progress >= 90 ? 'progress-bar--uncomplete' : null,
    progress >= 100 ? 'progress-bar--complete' : 'progress-bar--incomplete',
  );

  return (
    <div className={classes} title={tooltip}>
      {title ? (
        <div className='progress-bar__title'>
          <Label message={title} />
        </div>
      ) : null}
      <div className='progress-bar__bar' style={{ width: `${progress}%` }}>
        <span className='progress-bar__current'>
          <span className='progress-bar__value-label'>{valueLabel}</span>
          {current}
        </span>
      </div>
      <span className='progress-bar__required'>
        <span className='progress-bar__value-label'>{valueLabel}</span>
        {required}
      </span>
    </div>
  );
};
export default React.memo(ProgressBar);
