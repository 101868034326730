import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { centsToDecimal, DateService } from '@apollo/core';
import Label from '../../../../shared/components/I18n/Label';
import { getLimitWarning } from '../../../../shared/components/Form/formConfig/responsibleGaming';

const RenderMainStateRow = ({
  limit: { value, future, type, balance, dateFrom },
  currencySymbol = '$',
}) => {
  const left = useMemo(() => (balance ? value - balance : value), [value, balance]);

  const futureMarkup = useMemo(() => {
    const warning = getLimitWarning(future, currencySymbol);

    if (!warning) return null;

    return (
      <span className='message warning-message'>
        <Label {...warning} />
      </span>
    );
  }, [future, currencySymbol]);

  const infoMarkup = useMemo(
    () => (
      <span className='message info-message'>
        <Label
          message='limit set'
          params={{ date: DateService.getFormattedDateWithTime(dateFrom) }}
        />
      </span>
    ),
    [dateFrom],
  );

  return (
    <div className='limits-readonly-row'>
      <div>
        <Label className='limits-readonly-row__label' message={type} />
        <span className='limits-readonly-row__value'>{`${currencySymbol}${centsToDecimal(`${value}`)}`}</span>
        <span className='limits-readonly-row__left'>
          (
          {`${currencySymbol}${centsToDecimal(`${left > 0 ? left : 0}`)}`}
          <Label message='limit left' className='limits-readonly-row__left__label' />
          )
        </span>
      </div>
      {future ? futureMarkup : infoMarkup}
    </div>
  );
};

RenderMainStateRow.defaultProps = {
  currencyIcon: '',
  notification: '',
  future: null,
};

RenderMainStateRow.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  notification: PropTypes.string,
  future: PropTypes.shape({
    dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  currencyIcon: PropTypes.string,
};

export default RenderMainStateRow;
