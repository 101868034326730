function styleSheetExists(url) {
  return Array.from(document.getElementsByTagName('link'))
    .filter(({ rel }) => rel === 'stylesheet')
    .map(({ href }) => href)
    .includes(url);
}

export function addCssFileToHeadOnce(url) {
  if (!styleSheetExists(url)) {
    const link = document.createElement('link');
    link.href = url;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

function scriptExists(url) {
  return Array.from(document.getElementsByTagName('script'))
    .map(({ src }) => src)
    .includes(url);
}

export function addJsFileToBodyOnce({ url, onLoad }) {
  if (!scriptExists(url)) {
    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.addEventListener('load', onLoad);
    document.body.appendChild(script);
  } else {
    onLoad();
  }
}
