import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { onForgotPasswordSubmit, useActiveForm } from '@apollo/react-forms';
import ToastManager from '../../../core/ToastManager';
import Label from '../../components/I18n/Label';
import FormCom from '../../components/Form/FormCom';
import Loader from '../../components/Loader';
import AuthBlock from '../AuthBlock/AuthBlock';

const RESPONSE_RESET_TYPE_PHONE = 'PHONE';

const ForgotPassword = () => {
  const {
    location: { pathname },
    push: historyPush,
  } = useHistory();
  const { activeType, submit } = useActiveForm('INIT_PASSWORD_RESET', onForgotPasswordSubmit);
  const submitHandler = useCallback(
    (...props) => submit(...props).then((response) => {
      if (response.resetType === RESPONSE_RESET_TYPE_PHONE) {
        historyPush(`${pathname}?cmd=resetPasswordPhone&token=${response.publicToken}`);
        return;
      }

      ToastManager.success(() => (
        <Label message='Password reset instruction was sent to your email' />
      ));
      historyPush(pathname);
    }),
    [submit],
  );

  const renderFormFields = activeType ? (
    <FormCom buttonText='restore_password' form={activeType.steps[0]} action={submitHandler} />
  ) : (
    <Loader />
  );

  return (
    <AuthBlock>
      <div className='b-row'>
        <div className='auth-block-modal__form b-col forgot-password__form'>
          <header className='auth-block-modal__container__header'>
            <h1 className='auth-block-modal__container__header__title'>
              <Label message='restore_password' />
            </h1>
          </header>

          <div className='auth-block-modal__form__top'>
            <div className='auth-block-modal__form__top__inputs stretch'>
              <div className='block'>{renderFormFields}</div>
            </div>
          </div>
        </div>
      </div>
    </AuthBlock>
  );
};

export default ForgotPassword;
