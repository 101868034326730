import React from 'react';
import cx from 'classnames';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { COMPONENT_TYPES } from '@apollo/routing';
import { Bus, SiteConfigManager, useApplicationState } from '@apollo/core';
import useTranslate from '../I18n/Interpreter';
import { nextToJumpPath } from '../../../features/Racing/RacingPathHandler';

const MobileNavFooter = () => {
  const { layout } = useApplicationState();
  const t = useTranslate();
  const { pathname } = useLocation();

  const racingRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.RACING);
  const sportRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.SPORT_PREMATCH);
  const promotionsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS);

  const toggleMobileMenu = React.useCallback(() => {
    Bus.send({
      event: Bus.events.layout.setMainMenu,
      data: !layout.mainMenu,
    });
  }, [Bus, layout]);

  return (
    <>
      {layout.mobileDevice === true
      && layout.sideBarLeft !== true
      && layout.sideBarRight !== true ? (
        <div className='footer-mobile-nav'>
          <div className='footer-mobile-nav__container'>
            <div className='nav_items'>
              <div className='nav_item nav_item--home'>
                <Link to='/' className={cx({ active: pathname === '/' })}>
                  <i className='icons icon--home' title={t('Home')} />
                  <div className='nav-title'>{t('Home')}</div>
                </Link>
              </div>
              <div className='nav_item nav_item--racing'>
                <Link
                  to={`${racingRoute}${nextToJumpPath}`}
                  className={cx({
                    active: matchPath(pathname, `${racingRoute}`),
                  })}
                >
                  <i className='icons icon--racing' title={t('racing')} />
                  <div className='nav-title'>{t('racing')}</div>
                </Link>
              </div>
              <div className='nav_item nav_item--promotions'>
                <Link
                  to={promotionsRoute}
                  className={cx({
                    active: matchPath(pathname, `${promotionsRoute}`),
                  })}
                >
                  <i className='icons icon--bet-bonus-all' title={t('promotions')} />
                  <div className='nav-title'>{t('promotions')}</div>
                </Link>
              </div>
              <div className='nav_item nav_item--sport'>
                <Link to={sportRoute} className={cx({ active: matchPath(pathname, sportRoute) })}>
                  <i className='icons icon--sports' title={t('sport')} />
                  <div className='nav-title'>{t('sport')}</div>
                </Link>
              </div>
              <div className='nav_item nav_item--more'>
                <button
                  className={`navbar-toggler ${
                    layout.sideBarLeft !== true ? 'navbar-toggler--collapsed' : ''
                  }`}
                  type='button'
                  aria-controls='navbarSupportedContent'
                  aria-label='Toggle navigation'
                  onClick={toggleMobileMenu}
                >
                  <i className='icons icon--more' title={t('My Profile')} />
                  <div className='nav-title'>{t('more')}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        ) : null}
    </>
  );
};

export default React.memo(MobileNavFooter);
