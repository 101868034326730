import React from 'react';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Label from '../../components/I18n/Label';
import AuthBlock from '../AuthBlock/AuthBlock';

const EmailVerification = () => {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isSuccessful = searchParams.get('isSuccessful') === 'true';

  const className = cx([
    'b-row',
    'email-verification',
    isSuccessful ? 'email-verification--success' : 'email-verification--error',
  ]);

  return (
    <AuthBlock>
      <div className={className}>
        <div className='auth-block-modal__form b-col'>
          <header className='auth-block-modal__container__header'>
            <h1 className='auth-block-modal__container__header__title'>
              {isSuccessful ? (
                <span className='icon AkIcon-check-icon success' />
              ) : (
                <span className='icon AkrIcon-warning error' />
              )}
              <Label
                message={
                  isSuccessful ? 'verification_successful_title' : 'verification_unsuccessful_title'
                }
              />
            </h1>
          </header>
          <div className='auth-block-modal__form__top'>
            <div className='auth-block-modal__form__top__inputs stretch'>
              <div className='block'>
                <div className='block__content'>
                  <div className='messages'>
                    <Label
                      message={
                        isSuccessful
                          ? 'verification_successful_message'
                          : 'verification_unsuccessful_message'
                      }
                    />
                  </div>
                  <div className='block__content__button'>
                    <Link to={pathname}>
                      <div className='bBasePrimary'>
                        <Label message='ok' />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthBlock>
  );
};

export default EmailVerification;
