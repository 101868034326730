import React from 'react';
import { useApplicationState } from '@apollo/core';
import cx from 'classnames';
import { isNumber } from 'lodash';
import Theme from 'themeSource/custom';
import HeaderInfoUnauthorized from './HeaderInfoUnauthorized';
import HeaderInfoAuthorized from './HeaderInfoAuthorized';
import BetSlipButton from './BetSlipButton/BetSlipButton';
import ToBetHistoryButton from '../../ToBetHistoryButton/ToBetHistoryButton';
import { BonusSelector } from '../../../../state/Bonus/Bonus';

const HeaderInfo = () => {
  const { authenticated, resolved, betsCount } = useApplicationState();
  const hasActiveDepositBonus = BonusSelector.hasActiveDepositBonus();

  const classes = cx(
    'header__info',
    authenticated ? 'header__info--authenticated' : '',
    hasActiveDepositBonus ? 'header__info--has-bonus' : '',
  );

  if (!resolved) {
    return null;
  }

  const ThemeHeaderInfoAuthorized = Theme.HeaderInfoAuthorized || HeaderInfoAuthorized;

  return (
    <>
      <div className={classes}>
        {authenticated ? <ThemeHeaderInfoAuthorized /> : <HeaderInfoUnauthorized />}
      </div>
      {authenticated && (
        <ToBetHistoryButton className='btn-my-bets'>
          <div className='badge__container'>
            {isNumber(betsCount.UNSETTLED) ? betsCount.UNSETTLED : 0}
          </div>
        </ToBetHistoryButton>
      )}
      <BetSlipButton />
    </>
  );
};

export default HeaderInfo;
