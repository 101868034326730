import React from 'react';
import ProgressBar from './ProgressBar';

const BonusBetTracker = ({ eligibilityStats }) => {
  const { requiredBetsCount = 0, eligibleBetsCount = 0 } = eligibilityStats;
  const required = requiredBetsCount;
  const current = Math.min(eligibleBetsCount, required);

  if (!required) {
    return null;
  }
  return (
    <div className='bonus-eligibility-limit bonus-eligibility-limit--bets'>
      <ProgressBar
        current={current}
        required={required}
        tooltip={`${current} out of ${required}`}
        title='bonus bets count'
      />
    </div>
  );
};

export default React.memo(BonusBetTracker);
