import React from 'react';
import {
  clientProfileRoutes,
  accountRoutes,
  CLIENT_PROFILE_COMPONENT_TYPES,
} from '@apollo/routing';
import { SiteConfigManager } from '@apollo/core';
import DetailMain from './DetailMain';
import ChangePassword from './ChangePassword';
import VerifyDocuments from './VerifyDocuments';
import GreenIdVerification from './GreenIdVerification';
import ClientProfileSubRouter from '../ClientProfileSubRouter';

const { profile } = clientProfileRoutes;

const DetailRouter = ({ tabs }) => {
  const useGreenIdVerification = SiteConfigManager.getConfig(
    'config.config.client.useGreenId',
    false,
  );

  const getAvailableRoutes = (clientProfilePath) => ({
    [CLIENT_PROFILE_COMPONENT_TYPES.PROFILE_MAIN]: {
      path: `${clientProfilePath}/${profile}/${accountRoutes.main}`,
      component: DetailMain,
    },
    [CLIENT_PROFILE_COMPONENT_TYPES.PROFILE_CHANGE_PASSWORD]: {
      path: `${clientProfilePath}/${profile}/${accountRoutes.changePassword}`,
      component: ChangePassword,
    },
    [CLIENT_PROFILE_COMPONENT_TYPES.PROFILE_VERIFY]: {
      path: `${clientProfilePath}/${profile}/${accountRoutes.verifyDocuments}`,
      component: useGreenIdVerification ? GreenIdVerification : VerifyDocuments,
    },
  });

  return (
    <ClientProfileSubRouter
      tabs={tabs}
      getAvailableRoutes={getAvailableRoutes}
      parentRoute={profile}
    />
  );
};

export default DetailRouter;
