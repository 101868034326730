import React from 'react';
import Label from '../../shared/components/I18n/Label';
import { SportAllLink } from '../../shared/components/Sport/SportLinks';
import useTranslate from '../../shared/components/I18n/Interpreter';

const SportNothingFound = ({ selectedSport = null, timeFilter = '48' }) => {
  const t = useTranslate();

  if (!selectedSport) {
    return (
      <div className='sport-page__content sport-page__content--nothing-found animate-fade-left-in'>
        <h3 className='nothing-found'>
          <Label message='nothing_found' />
        </h3>
      </div>
    );
  }

  return (
    <div className='sport-page__content sport-page__content--nothing-found animate-fade-left-in'>
      <h3 className='nothing-found'>
        <Label message='nothing_found' />
      </h3>

      {/*
      This translation is confusing and should be simplified using translate parameters
      e.g. t('there are no [sportName] events starting next [timeFilter]hr. clearing period', {
        sportName: selectedSport.name,
        timeFilter: timeFilter
      })
      */}
      <p>
        {`${t('there_are_no')} ${selectedSport?.name} ${t(
          'events_starting',
        )} ${t(`next_${timeFilter}hr`)}. ${t('clearing_period')}`}
      </p>

      <SportAllLink sportUrlName={selectedSport?.urlName} className='go-to-link'>
        {`${t('go_to')} ${t('all')} ${selectedSport?.name}`}
      </SportAllLink>
    </div>
  );
};

export default SportNothingFound;
