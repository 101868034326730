import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Sidebar from '../../../src/app/shared/components/Sidebar/Sidebar';

const SidebarLeftRouting = () => (
  <Switch>
    <Route
      path=''
      component={Sidebar}
    />
  </Switch>
);

export default SidebarLeftRouting;
