import SidebarLeftRouting from './SidebarLeftRouting';
import DetailPageHeader from './DetailPageHeader';

export default {
  USE_SIGN_UP_MODAL: true,
  USE_HORIZONTAL_PROFILE_MENU: true,
  HIDE_BONUS_NOTIFICATION_CANCEL_BUTTON: true,
  SidebarLeftRouting,
  DetailPageHeader,
};
